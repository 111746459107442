import {
    Typography
} from "@material-ui/core"
import React, { Fragment, useState } from 'react'
import httpService from 'state-mngt/services/data/http-service'
import {
    Automation,
    AutomationSchedule,
    AutomationTrigger,
} from 'types'
import { DeleteScheduleDialog } from './automations-editor/delete-schedule-dialog'
import Schedule from './schedule'
import {
    selectTempScheduleRules,
    tempKey,
    useAutomationsStore,
    useRulesStore
} from "./store"
import useClasses from './useClasses'
import { nullIfInThePast, tempId } from './util'
import { useLoadingStore } from "./useGetData"
import Loading from "./loading"
import useCurrentZone from "utils/hooks/useCurrentZone"
import AddNew from "./install/add-new"
import EmptyState from "./empty-state"
import useEquipmentAutomation from "utils/hooks/useEquipmentAutomation"

const Schedules = ({ dwellingId }: { dwellingId: number }) => {
    const classes = useClasses()
    const selectedZone = useCurrentZone()

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const rules = useRulesStore(selectTempScheduleRules(dwellingId, selectedZone))

    const automation = useEquipmentAutomation()
    const outputs = (automation?.outputs || []).filter(x => x.equipment?.type !== 'humidifier')
    const loading = useLoadingStore()

    const [localLoading, setLoading] = useState(false)

    // list of outputs that've already been selected
    const [selected, setSelected] = useState<number[]>([])

    const setSelectedOutputs = (outputs: number[]) => {
        setSelected(prev => [...prev, ...outputs])
    }

    const removeSelected = (outputs: number[]) =>
        setSelected(prev => prev.filter(x => !outputs.includes(x)))

    const hasAvailableOutputs = Boolean(outputs
        .filter(x => !Object.values(selected).includes(x.id)).length)

    const updateAutomation = (update: Partial<Automation>) => {
        useAutomationsStore.setState(prev => ({
            ...prev,
            ...(automation ? {
                [automation.id]: {
                    ...(prev[automation.id] || {}),
                    ...update,
                },
            } : {}),
        }))
    }

    const disableRule = async (ruleId: number) => {
        const rule = rules.find(x => x.id === ruleId)

        if (!rule) return console.warn('[disableRule] ', 'no rule found')
        if (!automation) return console.warn('[disableRule] ', 'no automation found')

        const updatedAutomation = {
            ...(automation || {}),
            rules: automation.rules
                .map(x => x.id === ruleId ?
                    { ...x, enabled: false } : x
                )
                .filter(x => x.id > 0)
        }

        if (rule.id > 0) {
            await httpService.post(`/automation/${automation.id}`, updatedAutomation)
        } else {

            // if this rule is new and unsaved
            // disable the rule and temporary rule
            // from state
            useRulesStore.setState(prev => {
                const update = Object.fromEntries(Object.entries(prev)
                    .map(([x, y]) => {
                        if ((`${x}` === `${ruleId}`) || (x === tempKey(ruleId))) {
                            return [x, { ...y, trigger: { ...y.trigger, enabled: false } }]
                        }
                        return [x, y]
                    }))

                return update
            })
        }

        const outputsToRemove = rule.outputs.map(x => x.id) || []
        removeSelected(outputsToRemove)
        useAutomationsStore.setState(prev => ({
            ...prev,
            [automation.id]: updatedAutomation,
        }))
    }

    const deleteRuleAndSaveAutomation = async (ruleId) => {
        setLoading(true)
        await disableRule(ruleId)
        setLoading(false)
        setIsDialogOpen(false)
    }

    const handleDelete = async (ruleId) => {
        if (rules.length <= 1) return setIsDialogOpen(true)
        await deleteRuleAndSaveAutomation(ruleId)
    }

    const onClickDialogYes = (ruleId) => () => {
        deleteRuleAndSaveAutomation(ruleId)
    }

    const onClickDialogCancel = () => {
        setIsDialogOpen(false)
    }

    // creates a new fake rule
    const createNewSchedule = () => {
        if (!automation) return console.error('[createNewSchedule] no automation found')

        const inputId = tempId()
        const triggerId = tempId()

        const trigger: AutomationTrigger = {
            id: triggerId,
            enabled: true,
            filtration: false,
            ventilation: false,
            humidity: false,
            inputs: [inputId],
            outputs: [outputs[0].id],
            override_on: false,
            override_timeout: null,
        }

        const input: Partial<AutomationSchedule> = {
            id: inputId,
            type: 'schedule',
            currently_triggered: false,
            weekday_awake_interval: 'on_10_off_20',
            weekday_sleep_interval: 'on_10_off_20',
            weekend_awake_interval: 'on_10_off_20',
            weekend_sleep_interval: 'on_10_off_20',
            weekend_start_time: '08:00:00',
            weekend_stop_time: '22:00:00',
            weekday_start_time: '08:00:00',
            weekday_stop_time: '22:00:00',
        }

        const update = {
            inputs: [...(automation?.inputs || []), input],
            rules: [...(automation?.rules || []), trigger],
            dwellingId,
        }

        // @ts-ignore - schedule has a different input type
        updateAutomation(update)
    }

    if (loading) return <Loading />

    return (
        <div className={classes.root}>
            <Typography style={{ marginBottom: '24px' }} variant='h6'>
                Schedules
            </Typography>
            <div className={classes.gridContainer}>
                {rules.filter(x => x?.trigger.enabled).map((rule) => (
                    <Fragment key={rule.id}>
                        <Schedule
                            rule={rule}
                            outputs={outputs}
                            availableOutputs={outputs.filter(x => !selected.includes(x.id))}
                            setSelectedOutputs={setSelectedOutputs}
                            removeSelected={removeSelected}
                            handleDelete={handleDelete}
                        />
                        <DeleteScheduleDialog
                            onClickYes={onClickDialogYes(rule.id)}
                            onClickCancel={onClickDialogCancel}
                            isOpen={Boolean(isDialogOpen)}
                            loading={localLoading}
                        />
                    </Fragment>
                ))}
                {(automation && hasAvailableOutputs) ? (
                    <AddNew
                        onClick={createNewSchedule}
                    >
                        Add schedule
                    </AddNew>
                ) : (
                    <EmptyState>No available equipment</EmptyState>
                )}
            </div>
        </div>
    )
}

export default Schedules
