import React, {
  useEffect,
  useState,
} from 'react'
import {
  deleteRule,
  resetTemporaryRule,
  selectTempInput,
  setAutomation,
  setTemporaryRuleInput,
  useRulesStore,
} from "./store"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core"
import {
  AutomationRuleCardNoPadding,
} from './automation-rule-card'
import RuleTitle from './rule-title'
import Footer from './footer'
import { useResetAfterTimeout } from './useRuleState'
import Stack from 'ui/stack'
import useIsTouched from './useIsTouched'
import SelectableEquipmentList from './selectable-equipment-list'
import useSaveTemporaryRuleToAutomation from './useSaveTemporaryRuleToAutomation'
import EntityHeader from './entity-header'
import { isNumber } from './util'

const formatData = ({ key, value }) => {
  if (key.includes('time')) return {
    [key]: `${value}:00`,
  }
  return {
    [key]: value,
  }
}

const Schedule = ({
  rule,
  outputs,
  handleDelete,
  removeSelected,
  availableOutputs,
  setSelectedOutputs,
}) => {
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  useResetAfterTimeout([success, error], [setSuccess, setError])
  const touched = useIsTouched(rule?.id, [
    `weekday_awake_interval`,
    `weekday_sleep_interval`,
    `weekday_start_time`,
    `weekday_stop_time`,
  ])

  const input = rule?.inputs.find(x => x.type === 'schedule')
  const tempInput = useRulesStore(selectTempInput(rule?.id, input?.id))
  const save = useSaveTemporaryRuleToAutomation(rule?.id)

  const handleChange = (e) => {
    if (!input) return
    if (!e.target.name || !e.target.value) return console.error('[handleChange] missing values')
    const data = formatData({
      key: e.target.name, value: e.target.value,
    })
    setTemporaryRuleInput(rule?.id, input.id, data)
  }

  const handleCancel = () => {
    resetTemporaryRule(rule?.id)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await save()
      // if (result) {
      //   setAutomation(rule?.automationId, result)
      //   if (isNumber(rule.id) && rule.id < 0) deleteRule(rule.id)
      // }
      setSuccess('Saved ✓')
    } catch (e) {
      console.error(e)
      setError('Couldn\'t save')
    }
    setLoading(false)
  }

  const _handleDelete = async () => {
    setDeleteLoading(true)
    await handleDelete(rule?.id)
    setDeleteLoading(false)
  }

  useEffect(() => {
    if (!rule?.id) return
    if (isNumber(rule.id) && rule.id < 0) {
      handleSave()
    }
  }, [rule?.id])

  const _loading = loading || deleteLoading
  if (!input || !tempInput) return null

  return (
    <AutomationRuleCardNoPadding>
      <Stack spacing={2}>
        <Box mx={2} mt={1}>
          <Stack spacing={2}>
            <EntityHeader
              loading={_loading}
              handleDelete={_handleDelete}
            >
              Schedule
            </EntityHeader>
            <RuleTitle>
              Activate
            </RuleTitle>
            <SelectableEquipmentList
              allOutputs={outputs}
              ruleId={rule?.id}
              availableOutputs={availableOutputs}
              removeSelected={removeSelected}
              setSelectedOutputs={setSelectedOutputs}
            />
          </Stack>
        </Box>
        <Box px={2}>
          <Stack spacing={2}>
            <RuleTitle>
              Awake schedule
            </RuleTitle>
            <FormControl
              size='small'
              variant='outlined'
              style={{
                width: '100%',
              }}
            >
              <InputLabel
                htmlFor={`weekday_awake_interval`}
              >
                Interval
              </InputLabel>
              <Select
                id={`weekday_awake_interval`}
                name={`weekday_awake_interval`}
                labelId={`weekday_awake_interval`}
                label='Interval'
                value={tempInput[`weekday_awake_interval`]}
                disabled={loading}
                onChange={handleChange}
              >
                <MenuItem
                  value='always_on'
                >
                  Always on
                </MenuItem>
                <MenuItem
                  value='always_off'
                >
                  Always off
                </MenuItem>
                <MenuItem
                  value='on_5_off_55'
                >
                  5 min ON, 55 min OFF
                </MenuItem>
                <MenuItem
                  value='on_5_off_25'
                >
                  5 min ON, 25 min OFF
                </MenuItem>
                <MenuItem
                  value='on_10_off_20'
                >
                  10 min ON, 20 min OFF
                </MenuItem>
                <MenuItem
                  value='on_20_off_20'
                >
                  20 min ON, 20 min OFF
                </MenuItem>
                <MenuItem
                  value='on_20_off_10'
                >
                  20 min ON, 10 min OFF
                </MenuItem>
              </Select>
            </FormControl>
            <Stack
              direction='row'
              wrap='nowrap'
              itemStyles={[{
                flex: 1,
              }]}
            >
              <FormControl
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  htmlFor={`weekday_start_time`}
                >
                  Start
                </InputLabel>
                <OutlinedInput
                  id={`weekday_start_time`}
                  name={`weekday_start_time`}
                  label='Interval'
                  type='time'
                  disabled={loading}
                  value={tempInput[`weekday_start_time`]}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  htmlFor={`weekday_stop_time`}
                >
                  End
                </InputLabel>
                <OutlinedInput
                  id={`weekday_stop_time`}
                  name={`weekday_stop_time`}
                  label='Interval'
                  type='time'
                  disabled={loading}
                  value={tempInput[`weekday_stop_time`]}
                  onChange={handleChange}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </FormControl>
            </Stack>
          </Stack>
          <Box mt={3}>
            <Stack spacing={2}>
              <RuleTitle>
                Asleep schedule
              </RuleTitle>
              <FormControl
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  htmlFor={`weekday_sleep_interval`}
                >
                  Interval
                </InputLabel>
                <Select
                  id={`weekday_sleep_interval`}
                  name={`weekday_sleep_interval`}
                  labelId={`weekday_sleep_interval`}
                  label='Interval'
                  disabled={loading}
                  value={tempInput[`weekday_sleep_interval`]}
                  onChange={handleChange}
                >
                  <MenuItem
                    value='always_on'
                  >
                    Always on
                  </MenuItem>
                  <MenuItem
                    value='always_off'
                  >
                    Always off
                  </MenuItem>
                  <MenuItem
                    value='on_5_off_55'
                  >
                    5 min ON, 55 min OFF
                  </MenuItem>
                  <MenuItem
                    value='on_5_off_25'
                  >
                    5 min ON, 25 min OFF
                  </MenuItem>
                  <MenuItem
                    value='on_10_off_20'
                  >
                    10 min ON, 20 min OFF
                  </MenuItem>
                  <MenuItem
                    value='on_20_off_20'
                  >
                    20 min ON, 20 min OFF
                  </MenuItem>
                  <MenuItem
                    value='on_20_off_10'
                  >
                    20 min ON, 10 min OFF
                  </MenuItem>
                </Select>
              </FormControl>
              <Stack
                direction='row'
                wrap='nowrap'
                itemStyles={[{
                  flex: 1,
                }]}
              >
                <FormControl
                  size='small'
                  variant='outlined'
                  style={{
                    width: '100%',
                  }}
                >
                  <InputLabel
                    htmlFor={`weekday_stop_time`}
                  >
                    Start
                  </InputLabel>
                  <OutlinedInput
                    id={`weekday_stop_time`}
                    name={`weekday_stop_time`}
                    label='Interval'
                    type='time'
                    disabled
                    value={tempInput[`weekday_stop_time`]}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  size='small'
                  variant='outlined'
                  style={{
                    width: '100%',
                  }}
                >
                  <InputLabel
                    htmlFor={`weekday_start_time`}
                  >
                    End
                  </InputLabel>
                  <OutlinedInput
                    id={`weekday_start_time`}
                    name={`weekday_start_time`}
                    label='Interval'
                    type='time'
                    disabled
                    value={tempInput[`weekday_start_time`]}
                    onChange={handleChange}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Box mx={2} my={2}>
          <Footer
            success={success}
            error={error}
            loading={loading}
            touched={touched || input.id < 0} // if id id negative it's a new client-side schedule
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </Box>
      </Stack>
    </AutomationRuleCardNoPadding>
  )
}

export default Schedule
