import { makeStyles, Typography } from '@material-ui/core'
import alertNone from 'features/customer-drill-down/icons/alert-none.svg'
import React, { useEffect, useRef } from 'react'
import { AutomationRule } from 'types'
import {
  AirflowAlert,
  DewPointAlert,
  PMAlert,
  RHAlert,
  TemperatureAlert,
  TVOCAlert,
} from './alert'
import ColumnTitle from './column-title'
import Muted from './muted'

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '320px 1fr',
    gridGap: '32px',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '16px',
    '@media (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '@media (min-width: 1025px) and (max-width: 1344px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  noAlerts: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
}))

function AlertSettings({ rules }: { rules: { [key: string]: AutomationRule } }) {
  const styles = useStyles()

  const components = useRef({
    'dew-point-out-of-range': DewPointAlert,
    'no-airflow': AirflowAlert,
    'pm-high': PMAlert,
    'rh-out-of-range': RHAlert,
    'temperature-out-of-range': TemperatureAlert,
    'tvoc-high': TVOCAlert,
  }).current

  if (!Object.keys(rules).length) return null

  const renderAlertsByState = (isActive: boolean) => {
    const filteredRules = Object.keys(rules).filter(key => rules[key].trigger.enabled === isActive)

    if (!filteredRules.length) return null
    return filteredRules.map(key => {
      const Component = components[key]
      return <div key={rules[key].id}><Component ruleId={rules[key].id} /></div>
    })
  }

  const noActiveAlerts = Boolean(Object.keys(rules).filter(key => rules[key].trigger.enabled).length === 0)

  return (
    <div className={styles.container}>
      <div className={styles.flexContainer}>
        <ColumnTitle>Inactive alerts</ColumnTitle>
        {renderAlertsByState(false) || <Muted>No inactive alerts</Muted>}
      </div>
      <div className={styles.flexContainer}>
        <ColumnTitle>Active alerts</ColumnTitle>
        {!noActiveAlerts ?
          <div className={styles.gridContainer}>
            {renderAlertsByState(true)}
          </div>
          : null}
        {noActiveAlerts ? (
          <div className={styles.noAlerts}>
            <img
              alt="No alerts icon"
              src={alertNone}
              width="64"
              height="64"
            />
            <Typography>
              There are no active alerts
            </Typography>
            <Typography style={{ color: '#9bb3bf' }}>
              Activate alerts on the left
            </Typography>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default AlertSettings
