import { Automation, AutomationResponse, AutomationRule } from "types"
import {
  selectAutomation,
  selectRule,
  setAutomation,
  tempKey,
  useAutomationsStore,
  useRulesStore,
} from "./store"
import httpService from "state-mngt/services/data/http-service"
import { tempId } from "./util"

/**
 * saving an automation requires creating new inputs
 * with the changes and saving those to the automation
 */
const mergeRule = (tempRule: AutomationRule, automation: Automation): Automation => {
  const ruleId = tempRule.id

  // these are inputs that already existed but have been changed
  // const touchedInputs = tempRule.inputs.filter(x => x.touched)
  const touchedInputs = tempRule.inputs.filter(x => {
    const canonRule = automation.inputs.find(y => y.id === x.id)

    if (!canonRule) return false

    return x.start_set_point !== canonRule.start_set_point ||
      // @ts-ignore
      x.high_end_start_set_point !== canonRule.high_end_start_set_point ||
      // @ts-ignore
      x.low_end_start_set_point !== canonRule.low_end_start_set_point
  })

  // these are new inputs that do not yet exist in the database
  // const nonPersistedInputs = tempRule.inputs.filter(x => x.id < 0)

  const newInputs = touchedInputs.map(x => ({ ...x, id: tempId() }))
  const inputsToRemove = touchedInputs.map(x => x.id)

  // changed inputs are replaced with new input instances
  const newInputsAndExistingRuleInputs = [
    ...newInputs,
    ...tempRule.inputs.filter(x => !inputsToRemove.includes(x.id)),
  ]

  // apply temp outputs
  const newOutputs = automation.outputs.map(x => {
    const match = tempRule.outputs.find(y => y.id === x.id)
    if (match) return match
    return x
  })

  const ruleOutputs = tempRule.outputs.map(x => x.id)

  return {
    ...automation,
    rules: automation.rules.map((rule) => {
      if (rule.id === ruleId) return {
        ...rule,
        inputs: newInputsAndExistingRuleInputs.map(x => x.id),
        outputs: ruleOutputs,
      }
      return rule
    }),
    outputs: newOutputs,
    inputs: newInputsAndExistingRuleInputs,
  }
}

function useMergeTemporaryRuleIntoAutomation(ruleId) {
  const tempRule = useRulesStore(selectRule(tempKey(ruleId)))
  const automation = useAutomationsStore(selectAutomation(tempRule?.automationId))
  if (!tempRule || !automation) return null
  return mergeRule(tempRule, automation)
}

async function save(automation) {
  try {
    await httpService.post(`/automation/${automation.id}`, automation)
    const r = await httpService.get<AutomationResponse>(`/automation/${automation.id}`)
    setAutomation(automation.id, r)
    return { ...r, id: automation.id }
  } catch (e) {
    throw e
  }
}

/**
 * used for persisting an automation that has been
 * changed via a temporary rule. merges the temporary
 * rule into the automation, and then saves the changes
 */
function useSaveTemporaryRuleToAutomation(ruleId?: number) {
  if (!ruleId) return () => Promise.resolve()
  const mergedAutomation = useMergeTemporaryRuleIntoAutomation(ruleId)
  if (!mergedAutomation) return () => Promise.resolve()
  return () => save(mergedAutomation)
}

export {
  useMergeTemporaryRuleIntoAutomation,
}

export default useSaveTemporaryRuleToAutomation
