import useIsTouched from './useIsTouched'
import { deleteRule, resetTemporaryRule, selectTempRule, setAutomation, useRulesStore } from './store'
import { useEffect, useState } from 'react'
import useSaveTemporaryRuleToAutomation from './useSaveTemporaryRuleToAutomation'
import useEquipmentAutomation from 'utils/hooks/useEquipmentAutomation'
import { Automation, AutomationResponse } from 'types'
import httpService from 'state-mngt/services/data/http-service'

export const useResetAfterTimeout = (values, setters, ms = 60000) => {
  useEffect(() => {
    const tos = values.map((value, i) => setTimeout(() => setters[i](''), ms))
    return () => tos.map(x => clearTimeout(x))
  }, [...values])
}


function useCreateNewRule(ruleId) {
  const tempRule = useRulesStore(selectTempRule(ruleId))
  const automation = useEquipmentAutomation()

  const update = {
    ...automation,
    rules: [
      ...(automation?.rules || []),
      tempRule.trigger,
    ],
    inputs: [...(automation?.inputs || []), ...tempRule.inputs],
  }

  const create = async () => {
    if (!automation?.id) return console.error('No automation to save')

    try {
      await httpService.post(`/automation/${automation.id}`, update)
      const r = await httpService.get<AutomationResponse>(`/automation/${automation.id}`)

      if (r) {
        setAutomation(automation.id, r)
        deleteRule(tempRule.id)
      }
    } catch (e) {
      throw e
    }
  }

  return create
}

/**
 * save, cancel, touched state for an
 * automation rule. basically all the
 * persisting stuff that gets passed
 * to Footer
 */
const useRuleState = (ruleId: number) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const save = useSaveTemporaryRuleToAutomation(ruleId)
  const create = useCreateNewRule(ruleId)
  const touched = useIsTouched(ruleId)

  useResetAfterTimeout([success, error], [setSuccess, setError])

  const handleCancel = () => {
    if (ruleId < 0) {
      deleteRule(ruleId)
    } else {
      resetTemporaryRule(ruleId)
    }
  }

  const handleSave = async () => {
    setLoading(true)
    setError('')
    try {
      if (ruleId < 0) {
        await create()
      } else {
        await save()
      }
      setSuccess('Saved ✓')
    } catch (e) {
      console.error(e)
      // @ts-ignore
      setError(`Couldn't save: ${e?.message || 'server error'}`)
    }
    setLoading(false)
  }

  return {
    touched,
    loading,
    success,
    error,
    setError,
    setLoading,
    handleCancel,
    handleSave,
  }
}

export default useRuleState
