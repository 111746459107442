import {
    Box,
    Button,
    Grid,
    Typography
} from "@material-ui/core"
import { Add } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import {
    Interlock
} from 'types'
import AutomationRuleCard from './automation-rule-card'
import {
    selectFirstEquipmentAutomation,
    setAutomation,
    useAutomationsStore,
} from "./store"
import useClasses from './useClasses'
import { tempId } from './util'
import { useMixPanel } from "features/analytics"
import SingleInterlock from "./install/single-interlock"
import { isBlower } from "./install/new-controller"
import useCurrentZone from "utils/hooks/useCurrentZone"
import Footer from "./footer"
import httpService from "state-mngt/services/data/http-service"
import EmptyState from "./empty-state"
import AddNew from "./install/add-new"

const NewSchedule = ({
    automation,
    createNewSchedule,
}) => {
    if (!automation) return null

    return (
        <Grid
            xs={12}
            md={6}
            lg={4}
            item>
            <AutomationRuleCard style={{
                display: 'flex', justifyContent: 'center', paddingTop: 16,
            }}>
                <Button
                    onClick={createNewSchedule}
                    startIcon={<Add />}
                >New schedule</Button>
            </AutomationRuleCard>
        </Grid>
    )
}

const defaultInterlock = (primary_output, secondary_output): Interlock => ({
    primary_output,
    secondary_output,
    automation_id: 0,
    enabled: true,
    id: tempId(),
})

const Interlocks = ({ dwellingId }: { dwellingId: number }) => {
    const classes = useClasses()
    const zone = useCurrentZone()

    const equipmentAutomation = useAutomationsStore(selectFirstEquipmentAutomation(dwellingId, zone))
    const interlocks = (equipmentAutomation?.interlocks || []).filter(x => x.enabled)
    const outputs = equipmentAutomation?.outputs || []

    const [localInterlocks, setLocalInterlocks] = useState<Interlock[]>([])
    const [loading, setLoading] = useState(false)
    const [touched, setTouched] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const { mixpanel } = useMixPanel()

    useEffect(() => {
        if (!zone) return
        setLocalInterlocks(interlocks)
    }, [zone])

    useEffect(() => {
        setTouched(JSON.stringify(localInterlocks) !== JSON.stringify(interlocks))
    }, [
        JSON.stringify(localInterlocks),
        JSON.stringify(interlocks),
    ])

    useEffect(() => {
        if (!outputs?.length) return
        const interlocksWithOutputs = interlocks
            .filter(x => (x.primary_output) && (x.secondary_output))
            .filter(x =>
                outputs.some(y => (y.id === x.primary_output)) &&
                outputs.some(y => (y.id === x.secondary_output)))

        setLocalInterlocks(interlocksWithOutputs)
    }, [
        JSON.stringify(interlocks),
    ])

    const blowers = [
        ...outputs.filter(x => (x && isBlower(x.equipment?.type))),
    ]

    const nonBlowers = [
        ...outputs.filter(x => !(blowers.map(y => y.id).includes(x.id))),
    ]

    const usedNonBlowers = localInterlocks.flatMap(x => x.primary_output)

    const createNewInterlock = () => {
        if (!blowers[0] || !nonBlowers[0]) return
        setLocalInterlocks(prev => ([...prev, defaultInterlock(nonBlowers[0].id, blowers[0].id)]))
    }

    const setNonBlower = index => primary_output => {
        setLocalInterlocks(prev => prev.map((x, i) => {
            if (index === i) {
                return {
                    ...x,
                    primary_output,
                }
            }
            return x
        }))
    }

    const setBlower = index => secondary_output => {
        setLocalInterlocks(prev => prev.map((x, i) => {
            if (index === i) {
                return {
                    ...x,
                    secondary_output,
                }
            }
            return x
        }))
    }

    React.useEffect(() => {
        if (mixpanel) {
            mixpanel.track_pageview()
        }
    }, [mixpanel])

    const handleSave = async () => {
        if (!equipmentAutomation) return
        setLoading(true)
        const updatedAutomation = { ...equipmentAutomation, interlocks: localInterlocks }
        try {
            await httpService.post(`/automation/${equipmentAutomation.id}`, updatedAutomation)
            setAutomation(equipmentAutomation.id, updatedAutomation)
        } catch (e) {
            console.error(e)
            // @ts-ignore
            setError(e.message || "Couldn't save")
        }
        setLoading(false)
    }

    const handleCancel = () => {
        setLocalInterlocks(interlocks)
    }

    const handleDelete = index => async () => {
        if (!equipmentAutomation) return
        setLoading(true)
        const updatedInterlocks = localInterlocks.map((x, i) => ({ ...x, ...(i === index ? { enabled: false } : {}) }))
        const updatedAutomation = { ...equipmentAutomation, interlocks: updatedInterlocks }

        try {
            await httpService.post(`/automation/${equipmentAutomation.id}`, updatedAutomation)
            setLocalInterlocks(updatedInterlocks.filter(x => x.enabled))
            setAutomation(equipmentAutomation.id, updatedAutomation)
        } catch (e) {
            // @ts-ignore
            console.error(e?.message)
        }

        setLoading(false)
        // deleting an interlock removes it from the local state, the automation state, and then saves the automation
    }

    const hasError = (index) =>
        !localInterlocks[index].primary_output ||
        !localInterlocks[index].secondary_output

    const renderFooter = index => () =>
        <Box mt={2}>
            <Footer
                error={hasError(index)}
                loading={loading}
                handleSave={handleSave}
                handleCancel={handleCancel}
                success={success}
                touched={touched}
            />
        </Box>

    return (
        <div className={classes.root}>
            <Typography
                style={{ marginBottom: '24px' }}
                variant='h6'>
                Interlocks
            </Typography>
            <Typography variant='subtitle1'>
                Interlocking ensures non-blower equipment is never activated without a blower
            </Typography>
            <Box
                mt={4}
                display='grid'
                gridGap='16px'
                width='100%'
                gridTemplateColumns={[
                    '1fr',
                    '1fr 1fr',
                    '400px 400px 400px',
                ]}
                alignItems='start'
            >
                {localInterlocks.map(({ primary_output, secondary_output }, index) => (
                    <SingleInterlock
                        key={`${primary_output}${secondary_output}`}
                        nonBlowerId={primary_output}
                        blowerId={secondary_output}
                        setBlowerId={setBlower(index)}
                        setNonBlowerId={setNonBlower(index)}
                        blowers={blowers}
                        nonBlowers={nonBlowers}
                        renderFooter={renderFooter(index)}
                        loading={loading}
                        handleDelete={handleDelete(index)}
                    />
                ))}
                {(usedNonBlowers.length !== nonBlowers.length) && blowers.length ? (
                    <AddNew onClick={createNewInterlock}>
                        New interlock
                    </AddNew>
                ) : <EmptyState>No equipment to interlock</EmptyState>}
            </Box>
        </div>
    )
}

export { defaultInterlock }
export default Interlocks
