import {
  Button,
  debounce,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core'
import airflow from 'features/customer-drill-down/icons/airflow.svg'
import emailIcon from 'features/customer-drill-down/icons/email.svg'
import filter from 'features/customer-drill-down/icons/filter.svg'
import humidity from 'features/customer-drill-down/icons/humidification.svg'
import temperature from 'features/customer-drill-down/icons/temperature.svg'
import ventilation from 'features/customer-drill-down/icons/ventilation.svg'
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  AutomationInput,
  AutomationRangeInput,
  SetPoint,
} from 'types'
import Stack from 'ui/stack'
import Switch from 'ui/switch'
import { startPointToFactor } from './airflow'
import AutomationRuleCard from './automation-rule-card'
import ButtonSpinner from './button-spinner'
import Footer from './footer'
import InnerCard from './inner-card'
import Muted from './muted'
import RuleTitle from './rule-title'
import {
  selectRule,
  selectTempInput,
  selectTempRule,
  setTemporaryRule,
  setTemporaryRuleInput,
  useInitTemporaryRule,
  useRulesStore,
} from './store'
import useRuleState from './useRuleState'
import {
  displayRange,
  findUnit,
  hourToSecond,
  max,
  min,
  secondToHour,
  start_set_point,
  stop_set_point,
  useSkipFirstRender,
} from './util'
import useToggleEnabledState from 'utils/hooks/useToggleEnabledState'
import useConvertParticulateUnit from 'utils/hooks/useConvertParticulateUnit'
import useConvertTemperatureUnits from 'utils/hooks/useConvertTemperatureUnits'

function Semibold(props) {
  return (
    <span style={{ fontWeight: 'bold' }} {...props} />
  )
}

const unitShorthands = {
  'celsius': '°C',
  'fahrenheit': '°F',
}

const useCamRangeAlertState = (rule) => {
  useInitTemporaryRule(rule?.id)
  const tempInput = useRulesStore(selectTempInput<AutomationRangeInput>(rule.id, rule.inputs[0]?.id))

  const ruleState = useRuleState(rule.id)
  const enabled = rule?.trigger.enabled
  const rising = tempInput?.rising || true
  const unit = tempInput?.set_point_unit || 'celsius'
  const measurement = tempInput?.measurement || 'dew_point'

  const low = tempInput?.low_end_start_set_point
  const high = tempInput?.high_end_start_set_point
  const duration = secondToHour(tempInput?.detection_duration)

  const { display, format, convertedUnit } = useConvertTemperatureUnits(tempInput)

  const _min = min(measurement, unit, rising)
  const _max = max(measurement, unit, rising)

  const unitShorthand = unitShorthands[convertedUnit || 'celsius']

  return {
    display,
    format,
    unitShorthand,
    tempInput,
    duration,
    enabled,
    high,
    low,
    min: _min,
    max: _max,
    ruleState,
  }
}

const useGenerateMarks = (min: number, max: number, suffix: string) => {
  const [marks, setMarks] = useState<{ value: number, label: string }[]>([])

  useEffect(() => {
    if (!min || !max) return

    const marks = [{
      value: min,
      label: `${min}${suffix}`,
    }, {
      value: max,
      label: `${max}${suffix}`,
    }]

    setMarks(marks)
  }, [min, max, suffix])

  return marks
}

const useSingleThresholdAlertState = (rule) => {
  const tempInput = useRulesStore(selectTempInput<AutomationInput>(rule.id, rule.inputs[0]?.id))
  const ruleState = useRuleState(rule.id)
  const enabled = rule?.trigger.enabled
  const rising = tempInput?.rising || true
  const measurement = tempInput?.measurement || ''
  const startPoint = tempInput?.start_set_point
  const duration = secondToHour(tempInput?.detection_duration)
  const unit = findUnit(measurement)

  return {
    measurement,
    startPoint,
    tempInput,
    duration,
    enabled,
    rising,
    unit,
    ruleState,
  }
}

function Alert({ children, enabled, renderTitle, ruleId }) {
  const { toggle, loading } = useToggleEnabledState(ruleId)

  return (
    <AutomationRuleCard
      style={{
        minWidth: '320px',
        maxWidth: '600px',
      }}
    >
      <Stack direction="row" justify="flex-start">
        <div style={{ width: '28px', height: '16px' }}>
          {loading ? (<ButtonSpinner />) : (
            <Switch
              disabled={loading}
              checked={enabled || false}
              onChange={toggle}
            />
          )}
        </div>
        {renderTitle()}
      </Stack>
      <Stack style={{ display: enabled ? 'flex' : 'none' }}>{children}</Stack>
    </AutomationRuleCard>
  )
}

function RangeInput(props) {
  return <Slider {...props} />
}

/**
 * - TODO
 * - setting email should set it on the temp rule
 * - if a user unchecks the use for all the temp
 * rule email should be reset for all except the
 * currently editing
 * - saving just saves the temp rules to the automations
 */
const ViaEmail = ({ ruleId }) => {
  const [editing, setEditing] = useState(false)
  const [error, setError] = useState(false)
  const tempRule = useRulesStore(selectTempRule(ruleId))

  // @ts-ignore
  const [value, setValue] = useState(tempRule?.outputs[0].email || '')

  // @ts-ignore
  const emailAddress = tempRule?.outputs[0].email || ''

  const debounceSet = useRef(debounce((ruleId, email, outputs) => {
    setTemporaryRule(ruleId, {
      outputs: outputs.map(output => ({ ...output, email })),
    })
  }, 300)).current

  useSkipFirstRender(() => {
    if (!tempRule) return
    debounceSet(ruleId, value, tempRule.outputs)
  }, [ruleId, value, tempRule?.id])

  useSkipFirstRender(() => {
    if (!emailAddress) return
    setValue(emailAddress)
  }, [emailAddress])

  const onChange = e => {
    setValue(e.target.value)
    const regex = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/i)
    const hasNoMatch = e.target.value.match(regex) === null
    setError(hasNoMatch)
  }

  return (
    <Stack spacing={1}>
      <RuleTitle>Alert</RuleTitle>
      <InnerCard>
        <Stack
          direction='row'
          spacing={1}
          justify='flex-start'
          itemStyles={[{}, { flexGrow: 1 }]}
        >
          <img
            style={{ marginBottom: '-5px' }}
            alt="Email icon"
            src={emailIcon}
            width="18"
            height="18"
          />
          {editing ? (
            <Stack
              direction='row'
              w='100%'
              justify='space-between'
              itemStyles={[{ flexGrow: 1 }]}
            >
              <TextField
                fullWidth
                error={error}
                helperText={error ? 'Invalid email' : ''}
                autoFocus
                value={value}
                onChange={onChange}
              />
            </Stack>
          ) : (
            <Stack
              direction='row'
              justify='space-between'
              itemStyles={[{ flexGrow: 1 }, {}]}
            >
              <Typography>
                {emailAddress.length > 20 ? `${emailAddress.slice(0, 18)}...` : emailAddress}
              </Typography>
              <Button
                color='primary'
                onClick={() => setEditing(prev => !prev)}>Edit</Button>
            </Stack>
          )}
        </Stack>
        {/* {
          editing ? (
            <Stack
              spacing={1}
              justify='flex-start'
              direction='row'
              style={{ marginTop: '8px' }}
            >
              <Checkbox
                checked={useForAll}
                onChange={onChangeUseForAll}
                className={classes.checkbox}
                size='small' />
              <p className={classes.caption}>Use this email for all alerts</p>
            </Stack>
          ) : null
        } */}
      </InnerCard >
    </Stack >
  )
}

// local state that may be converted based on
// user preferences, therefore it's independent
// of global or server state
// this also has the advantage of not triggereing
// global store renders until changes are committed
function useConvertedLocalState({
  display,
  format,
  high,
  low,
  _min,
  _max,
  unitShorthand,
  ruleId,
  inputId,
}) {
  const [values, setValues] = useState<number[]>([low, high].map(display))

  // keep values synced to the rule
  useEffect(() => {
    setTimeout(() => setValues([low, high].map(display)), 100)
  }, [low, high, display])

  const displayedMin = display(_min)
  const displayedMax = display(_max)

  const marks = useGenerateMarks(displayedMin, displayedMax, unitShorthand)

  const handleChange = (e: ChangeEvent<{}>, v: number[]) => {
    setValues(v)
  }

  const handleChangeCommitted = (event, values) => {
    const _values = values.map(format)
    setTemporaryRuleInput(ruleId, inputId, {
      low_end_start_set_point: _values[0],
      high_end_start_set_point: _values[1],
    })
  }

  return {
    values,
    marks,
    displayedMin,
    displayedMax,
    handleChangeCommitted,
    handleChange,
  }
}

function useValidateRange(values: number[], setError, minDiff = 2) {
  useEffect(() => {
    if ((values[1] - values[0]) < minDiff) return setError('Range is too narrow')
    setError('')
  }, [
    values[0],
    values[1],
    minDiff,
  ])
}

function DewPointAlert({
  ruleId,
}) {
  const rule = useRulesStore(selectRule(ruleId))

  const {
    unitShorthand,
    ruleState,
    tempInput,
    duration,
    enabled,
    display,
    format,
    high,
    low,
    min: _min,
    max: _max,
  } = useCamRangeAlertState(rule)

  const {
    values,
    marks,
    displayedMin,
    displayedMax,
    handleChange,
    handleChangeCommitted,
  } = useConvertedLocalState({
    unitShorthand,
    ruleId: rule?.id,
    inputId: tempInput?.id,
    display,
    format,
    high,
    low,
    _min,
    _max,
  })

  useValidateRange(values, ruleState.setError, unitShorthand === unitShorthands['celsius'] ? 5 : 9)

  const handleChangeTime = (event, value) => {
    setTemporaryRuleInput(rule.id, tempInput.id, {
      detection_duration: hourToSecond(value),
    })
  }

  return (
    <Alert
      enabled={enabled}
      ruleId={ruleId}
      renderTitle={() => (
        <Stack justify="flex-start" direction="row" spacing={1}>
          <img
            style={{ marginBottom: '-4px' }}
            alt="Humidity icon"
            src={humidity}
            width="18"
            height="18"
          />
          <Typography>Dew point out of range</Typography>
        </Stack>
      )}
    >
      <ViaEmail ruleId={ruleId} />
      <Stack spacing={1}>
        <RuleTitle>When condition is met</RuleTitle>
        <InnerCard>
          <Typography>When <Semibold>indoor dew point level</Semibold> is outside of range <Semibold>{values[0]} {unitShorthand}</Semibold> and <Semibold>{values[1]} {unitShorthand}</Semibold></Typography>
          <RangeInput
            min={displayedMin}
            max={displayedMax}
            value={[
              values[0] || 0,
              values[1] || 1,
            ]}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            marks={marks}
          />
        </InnerCard>
      </Stack>
      <Stack spacing={1}>
        <RuleTitle>For</RuleTitle>
        <InnerCard>
          <Typography>At least <Semibold>{duration} hours</Semibold></Typography>
          <Slider
            value={duration}
            min={1}
            max={48}
            onChange={handleChangeTime}
          />
        </InnerCard>
      </Stack>
      <Footer
        {...ruleState}
      />
    </Alert>
  )
}

function TVOCAlert({
  ruleId,
}) {
  const rule = useRulesStore(selectRule(ruleId))

  const {
    measurement,
    startPoint,
    ruleState,
    tempInput,
    duration,
    enabled,
    unit,
  } = useSingleThresholdAlertState(rule)

  const handleChangeTime = (event, value) => {
    setTemporaryRuleInput(rule.id, tempInput.id, {
      detection_duration: hourToSecond(value),
    })
  }

  const handleChange = (event, value) => {
    setTemporaryRuleInput(ruleId, tempInput?.id, {
      start_set_point: start_set_point[measurement][unit][value],
      stop_set_point: stop_set_point[measurement][unit][value],
    })
  }

  const { convertedUnit } = useConvertParticulateUnit(measurement, unit)
  const factor = startPointToFactor(measurement)(unit)(startPoint)

  return (
    <Alert
      {...{ enabled, ruleId }}
      renderTitle={() => (
        <Stack justify="flex-start" direction="row" spacing={1}>
          <img
            style={{ marginBottom: '-4px' }}
            alt="Ventilation icon"
            src={ventilation}
            width="18"
            height="18"
          />
          <Typography>tVOC High</Typography>
        </Stack>
      )}
    >
      <ViaEmail ruleId={ruleId} />
      <Stack spacing={1}>
        <RuleTitle>When condition is met</RuleTitle>
        <InnerCard>
          <Typography>When <Semibold>indoor tVOC levels</Semibold> are worse than <Semibold>{factor}</Semibold></Typography>
          <RadioGroup
            value={factor}
            aria-label={measurement}
            name={measurement}
            onChange={handleChange}>
            <FormControlLabel value='fair' control={<Radio />} label={
              <Typography>
                {displayRange[measurement]?.[convertedUnit]?.['fair']}
                <Muted> (fair)</Muted>
              </Typography>
            } />
            <FormControlLabel value='poor' control={<Radio />} label={
              <Typography>
                {displayRange[measurement]?.[convertedUnit]?.['poor']}
                <Muted> (poor)</Muted>
              </Typography>
            } />
            {/* <FormControlLabel value='custom' control={<Radio />} label={
                        <Typography>
                            Custom
                        </Typography>
                    } /> */}
            {/* {(selectedFactor === 'custom') ? (
            <TextField
              autoFocus
              className={classes.textField}
              value={customSetPoint}
              onChange={onChangeCustomSetPoint}
            />
          ) : null} */}
          </RadioGroup>
        </InnerCard>
      </Stack>
      <Stack spacing={1}>
        <RuleTitle>For</RuleTitle>
        <InnerCard>
          <Typography>At least <Semibold>{duration} hours</Semibold></Typography>
          <Slider
            value={duration}
            min={1}
            max={48}
            onChange={handleChangeTime}
          />
        </InnerCard>
      </Stack>
      <Footer
        {...ruleState}
      />
    </Alert>
  )
}

function PMAlert({
  ruleId,
}) {
  const rule = useRulesStore(selectRule(ruleId))

  const {
    measurement,
    startPoint,
    ruleState,
    tempInput,
    duration,
    enabled,
    unit,
  } = useSingleThresholdAlertState(rule)

  const handleChangeTime = (event, value) => {
    setTemporaryRuleInput(rule.id, tempInput.id, {
      detection_duration: hourToSecond(value),
    })
  }

  const handleChange = (event, value) => {
    setTemporaryRuleInput(ruleId, tempInput?.id, {
      start_set_point: start_set_point[measurement][unit][value],
      stop_set_point: stop_set_point[measurement][unit][value],
    })
  }

  const { convertedUnit } = useConvertParticulateUnit(measurement, unit)
  const factor = startPointToFactor(measurement)(unit)(startPoint)

  return (
    <Alert
      {...{ enabled, ruleId }}
      renderTitle={() => (
        <Stack justify="flex-start" direction="row" spacing={1}>
          <img
            style={{ marginBottom: '-4px' }}
            alt="Filter icon"
            src={filter}
            width="18"
            height="18"
          />
          <Typography>PM2.5 High</Typography>
        </Stack>
      )}
    >
      <ViaEmail ruleId={ruleId} />
      <Stack spacing={1}>
        <RuleTitle>When condition is met</RuleTitle>
        <InnerCard>
          <Typography>When <Semibold>indoor PM2.5 levels</Semibold> are worse than <Semibold>{factor}</Semibold></Typography>
          <RadioGroup
            value={factor}
            aria-label={measurement}
            name={measurement}
            onChange={handleChange}>
            <FormControlLabel value='fair' control={<Radio />} label={
              <Typography>
                {displayRange[measurement]?.[convertedUnit]?.['fair']}
                <Muted> (fair)</Muted>
              </Typography>
            } />
            <FormControlLabel value='poor' control={<Radio />} label={
              <Typography>
                {displayRange[measurement]?.[convertedUnit]?.['poor']}
                <Muted> (poor)</Muted>
              </Typography>
            } />
            {/* <FormControlLabel value='custom' control={<Radio />} label={
                        <Typography>
                            Custom
                        </Typography>
                    } /> */}
            {/* {(selectedFactor === 'custom') ? (
            <TextField
              autoFocus
              className={classes.textField}
              value={customSetPoint}
              onChange={onChangeCustomSetPoint}
            />
          ) : null} */}
          </RadioGroup>
        </InnerCard>
      </Stack>
      <Stack spacing={1}>
        <RuleTitle>For</RuleTitle>
        <InnerCard>
          <Typography>At least <Semibold>{duration} hours</Semibold></Typography>
          <Slider
            value={duration}
            min={1}
            max={48}
            onChange={handleChangeTime}
          />
        </InnerCard>
      </Stack>
      <Footer
        {...ruleState}
      />
    </Alert>
  )
}

function AirflowAlert({
  ruleId,
}) {
  const rule = useRulesStore(selectRule(ruleId))

  const {
    ruleState,
    tempInput,
    duration,
    enabled,
  } = useSingleThresholdAlertState(rule)

  const handleChangeTime = (event, value) => {
    setTemporaryRuleInput(rule.id, tempInput.id, {
      detection_duration: hourToSecond(value),
    })
  }

  return (
    <Alert
      {...{ enabled, ruleId }}
      renderTitle={() => (
        <Stack justify="flex-start" direction="row" spacing={1}>
          <img
            style={{ marginBottom: '-4px' }}
            alt="Airflow icon"
            src={airflow}
            width="18"
            height="18"
          />
          <Typography>No airflow</Typography>
        </Stack>
      )}
    >
      <ViaEmail ruleId={ruleId} />
      <Stack spacing={1}>
        <RuleTitle>When condition is met</RuleTitle>
        <InnerCard>
          <Typography>When <Semibold>indoor airflow</Semibold> is not detected</Typography>
        </InnerCard>
      </Stack>
      <Stack spacing={1}>
        <RuleTitle>For</RuleTitle>
        <InnerCard>
          <Typography>At least <Semibold>{duration} hours</Semibold></Typography>
          <Slider
            value={duration}
            min={1}
            max={48}
            onChange={handleChangeTime}
          />
        </InnerCard>
      </Stack>
      <Footer
        {...ruleState}
      />
    </Alert>
  )
}

function RHAlert({
  ruleId,
}) {
  const rule = useRulesStore(selectRule(ruleId))

  const {
    ruleState,
    tempInput,
    duration,
    enabled,
    high,
    low,
    min: _min,
    max: _max,
  } = useCamRangeAlertState(rule)

  const [values, setValues] = useState([low, high])

  // keep values synced to the rule
  // thats in global state
  useEffect(() => {
    setTimeout(() => {
      setValues([low, high])
    }, 100)
  }, [high, low])

  const handleChangeTime = (event, value) => {
    setTemporaryRuleInput(rule.id, tempInput.id, {
      detection_duration: hourToSecond(value),
    })
  }

  const handleChange = (e, v) => setValues(v)

  useValidateRange(values, ruleState.setError, 10)

  const handleChangeCommitted = (event, values) => {
    setTemporaryRuleInput(rule.id, tempInput.id, {
      low_end_start_set_point: values[0],
      high_end_start_set_point: values[1],
    })
  }

  const marks = useGenerateMarks(_min, _max, '%')

  return (
    <Alert
      {...{ enabled, ruleId }}
      renderTitle={() => (
        <Stack justify="flex-start" direction="row" spacing={1}>
          <img
            style={{ marginBottom: '-4px' }}
            alt="Humidity icon"
            src={humidity}
            width="18"
            height="18"
          />
          <Typography>Humidity out of range</Typography>
        </Stack>
      )}
    >
      <ViaEmail ruleId={ruleId} />
      <Stack spacing={1}>
        <RuleTitle>When condition is met</RuleTitle>
        <InnerCard>
          <Typography>When <Semibold>indoor relative humidity</Semibold> is outside of range <Semibold>{values[0]}%</Semibold> and <Semibold>{values[1]}%</Semibold></Typography>
          <RangeInput
            min={_min}
            max={_max}
            value={[
              values[0] || 0,
              values[1] || 1,
            ]}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            marks={marks}
          />
        </InnerCard>
      </Stack>
      <Stack spacing={1}>
        <RuleTitle>For</RuleTitle>
        <InnerCard>
          <Typography>At least <Semibold>{duration} hours</Semibold></Typography>
          <Slider
            value={duration}
            min={1}
            max={48}
            onChange={handleChangeTime}
          />
        </InnerCard>
      </Stack>
      <Footer
        {...ruleState}
      />
    </Alert>
  )
}

function TemperatureAlert({
  ruleId,
}) {
  const rule = useRulesStore(selectRule(ruleId))

  const {
    unitShorthand,
    ruleState,
    tempInput,
    display,
    format,
    duration,
    enabled,
    high,
    low,
    min: _min,
    max: _max,
  } = useCamRangeAlertState(rule)

  const {
    values,
    marks,
    displayedMin,
    displayedMax,
    handleChange,
    handleChangeCommitted,
  } = useConvertedLocalState({
    unitShorthand,
    ruleId: rule?.id,
    inputId: tempInput?.id,
    display,
    format,
    high,
    low,
    _min,
    _max,
  })

  useValidateRange(values, ruleState.setError, unitShorthand === unitShorthands['celsius'] ? 5 : 9)

  const handleChangeTime = (event, value) => {
    setTemporaryRuleInput(rule.id, tempInput.id, {
      detection_duration: hourToSecond(value),
    })
  }

  return (
    <Alert
      {...{ enabled, ruleId }}
      renderTitle={() => (
        <Stack justify="flex-start" direction="row" spacing={1}>
          <img
            style={{ marginBottom: '-4px' }}
            alt="Temperature icon"
            src={temperature}
            width="18"
            height="18"
          />
          <Typography>Temperature out of range</Typography>
        </Stack>
      )}
    >
      <ViaEmail ruleId={ruleId} />
      <Stack spacing={1}>
        <RuleTitle>When condition is met</RuleTitle>
        <InnerCard>
          <Typography>When <Semibold>indoor temperature</Semibold> is outside of range <Semibold>{values[0]} {unitShorthand}</Semibold> and <Semibold>{values[1]} {unitShorthand}</Semibold></Typography>
          <RangeInput
            min={displayedMin}
            max={displayedMax}
            value={[
              values[0] || 0,
              values[1] || 1,
            ]}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            marks={marks}
          />
        </InnerCard>
      </Stack>
      <Stack spacing={1}>
        <RuleTitle>For</RuleTitle>
        <InnerCard>
          <Typography>At least <Semibold>{duration} hours</Semibold></Typography>
          <Slider
            value={duration}
            min={1}
            max={48}
            onChange={handleChangeTime}
          />
        </InnerCard>
      </Stack>
      <Footer
        {...ruleState}
      />
    </Alert>
  )
}

export {
  TemperatureAlert,
  RHAlert,
  DewPointAlert,
  PMAlert,
  TVOCAlert,
  AirflowAlert,
}

